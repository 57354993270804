import React, { FC } from 'react';

import { activationOrderDataType } from 'hooks/api/useActivationOrder';
import { Account } from 'types/ActivationOrderTypes';
import GeneralInformation from 'components/forms/ActivationOrderForm/General/common/GeneralInformation';
import DistributionPlatformList from 'components/forms/ActivationOrderForm/General/common/DistributionPlatformList';

type PropsT = {
  activationOrderData: activationOrderDataType;
  accounts: Account[];
  setStatus: any;
};

const AvailableReceivablesOA: FC<PropsT> = ({ activationOrderData, accounts, setStatus }) => (
  <>
    <GeneralInformation
      type="available_receivables"
      activationOrderData={activationOrderData}
      accounts={accounts}
      setStatus={setStatus}
    />
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    {activationOrderData.distribution_platforms?.length > 0 && (
      <DistributionPlatformList activationOrderData={activationOrderData} />
    )}
  </>
);

export default AvailableReceivablesOA;
