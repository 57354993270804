import React, { FC } from 'react';

import { paramsObjAO } from '../../ActivationOrderForm';
import { KeyCardsEnum } from '../data';
import ItemsAo from '../ItemsAo';

interface CardAvailableReceivablesType {
  params?: paramsObjAO;
}

const CardAvailableReceivables: FC<CardAvailableReceivablesType> = () => {
  return (
    <>
      <ItemsAo types={[KeyCardsEnum.AO_DATE, KeyCardsEnum.EMPTY]} />
      <ItemsAo types={[KeyCardsEnum.MFA_USD, KeyCardsEnum.MFA_EUR]} />
      <ItemsAo types={[KeyCardsEnum.DISTRIBUTION_PLATFORMS]} />
    </>
  );
};

export default CardAvailableReceivables;
