//@ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { Table, Pagination, type TableProps, Modal } from '@gamesb42/ui-kit';
import { Link, useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';

import TableWrapper from 'components/TableWrapper';
import { getToken } from 'helpers/token';

import s from './style.module.scss';

interface DataType {
  id: string;
  key: string;
  createdAt: string;
  userName: string;
  objectName: string;
}

interface QueryParams {
  page?: number;
  limit?: number;
}

export default function AuditLog() {
  const [loading, setLoading] = useState(true);
  const [auditData, setAuditData] = useState([]);
  const [loading2, setLoading2] = useState(true);
  const [recordData, setRecordData] = useState([]);
  const [recordId, setRecordId] = useState('');
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const query: QueryParams = queryString.parse(history.location.search, { parseNumbers: true });
  let { limit = 10, page = 0 } = query;
  //@ts-ignore
  const { companyId } = useParams();

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Date',
      align: 'left' as 'left',
      render: (_: any, record: any) => {
        return moment(record.createdAt, moment.ISO_8601).format('DD.MM.YYYY, HH:mm');
      },
    },
    {
      title: 'Author',
      align: 'left' as 'left',
      render: (_: any, record: any) => {
        return (
          <Link to={`/users/${record.userId}`} style={{ color: '#7145EC', textDecorationLine: 'underline' }}>
            {record.userName}
          </Link>
        );
      },
    },
    {
      title: 'Section',
      dataIndex: 'objectName',
      align: 'left' as 'left',
    },
  ];

  const recordColumns = [
    {
      title: 'Before',
      align: 'left' as 'left',
      render: (_: any, record: any) => {
        return (
          <div>
            {record.key}: <span style={{ color: '#E02A61' }}>{JSON.stringify(record.old)}</span>
          </div>
        );
      },
    },
    {
      title: 'After',
      align: 'left' as 'left',
      render: (_: any, record: any) => {
        return (
          <div>
            {record.key}: <span style={{ color: '#0E9C1C' }}>{JSON.stringify(record.new)}</span>
          </div>
        );
      },
    },
  ];

  const onChangeQuery = useCallback(
    (newValues: Partial<QueryParams>) => {
      history.replace(
        `${history.location.pathname}?${queryString.stringify(
          { limit, page, ...newValues },
          { skipEmptyString: true },
        )}`,
      );
    },
    [history, limit, page],
  );

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    fetch(`${process.env.REACT_APP_LOG_URL}/api/v1/log_records/company/${companyId}?page=${page}&page_size=${limit}`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.length > 0) setAuditData(result);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [limit, page]);

  useEffect(() => {
    if (recordId.length > 0) {
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${getToken()}`);

      try {
        fetch(`${process.env.REACT_APP_LOG_URL}/api/v1/log_records/${recordId}`, {
          method: 'GET',
          headers: myHeaders,
        })
          .then((response) => response.json())
          .then((result) => {
            const preparedData = Object.entries(result.delta_flat).reduce((acc, curr, i) => {
              acc.push({ key: curr[0], old: curr[1].old, new: curr[1].new });
              return acc;
            }, []);

            //@ts-ignore
            setRecordData(preparedData);
          })
          .catch(() => {})
          .finally(() => setLoading2(false));
      } catch (e) {
        console.log(e);
      }
    }
  }, [recordId]);

  return (
    <div className={s.container}>
      <TableWrapper>
        <Table
          loading={loading}
          onRow={({ id }) => ({
            onClick: () => {
              setRecordId(id);
              setOpen(true);
            },
          })}
          columns={columns}
          dataSource={auditData}
          className={s.table}
        />
        <Pagination
          total={auditData.length}
          current={(page + 1) as number}
          pageSize={limit as number}
          onChange={(newCurrent: number, newPageSize: number) =>
            onChangeQuery({ page: newCurrent - 1, limit: newPageSize })
          }
        />
      </TableWrapper>
      <Modal
        open={open}
        title="Change history"
        onOk={() => setOpen(false)}
        okButtonProps={{
          onMouseDown: () => {},
        }}
        okText="OK"
        onCancel={() => {
          setOpen(false);
        }}
        width="768px"
      >
        <Table
          loading={loading2}
          style={{ cursor: 'pointer' }}
          columns={recordColumns}
          dataSource={recordData}
          className={s.table2}
        />
      </Modal>
    </div>
  );
}
