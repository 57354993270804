import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { tableStyleAo } from 'theme/styles';
import { formatAmount } from 'helpers/formatters';
import useCompany, { listPdsAccountType } from 'hooks/api/useCompany';
import { CompanyTypeParams } from '../index';
import HeaderDailyRevenue from './headerDailyRevenue';

import styles from './styles.module.scss';
import DailyRevenueTable from 'components/DailyRevenueTable';

type StoreIn = { storeName: string; sum: number };

export const listCurrency = ['EUR', 'USD'];
interface PropsT {
  listPdsAccounts: listPdsAccountType;
  requestParams: CompanyTypeParams;
}
const DailyRevenue: FC<PropsT> = ({ listPdsAccounts, requestParams }) => {
  const { t } = useTranslation();
  const classes = tableStyleAo();
  const { listPdsRevenueByDays } = useCompany();

  const [currency, setCurrency] = useState(listCurrency[0]);

  const prepareColumns = () => {
    const allByStores = listPdsRevenueByDays?.proceeds?.map((store: any) => store?.byStores);

    if (allByStores?.length > 0) {
      let allStoresName = listPdsRevenueByDays.stores.map((store: any) => ({ storeName: store.name, sum: 0 }));
      const allByStoresFlat = allByStores.flat();

      allStoresName = allStoresName.map((el: any) => {
        const sum = allByStoresFlat
          .filter((el2: any) => el2.storeName === el.storeName)
          .map((el3: any) => el3.sum)
          .reduce((acc: number, cur: number) => acc + cur, 0);

        return { storeName: el.storeName, sum: formatAmount(sum) };
      });

      const allStoresNamesIcons = allByStores
        .reduce((acc: StoreIn[], cur: StoreIn) => {
          const curName = cur.storeName;
          const accNames = acc?.map((el) => el?.storeName);

          if (!accNames.includes(curName)) {
            acc.push(cur);
          }

          return acc;
        }, [])
        ?.flat();

      const outColumns = allStoresNamesIcons.map((el: any) => ({
        title: (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <div
              className={styles.icon}
              style={{
                backgroundImage: `url(${
                  listPdsRevenueByDays.stores.filter(
                    (store: { name: string; logo: string }) => store.name === el.storeName,
                  )[0].logo
                })`,
              }}
            />
            <div>{el.storeName}</div>
          </div>
        ),
        align: 'left' as 'left',
        children: [
          {
            title: (
              <div style={{ color: 'black' }}>
                {currency === 'EUR' ? '€' : '$'}
                {allStoresName.filter((el2: any) => el2.storeName === el.storeName)[0].sum}
              </div>
            ),
            render: (_: any, row: any) => {
              const sum = row.byStores.filter((el2: any) => el2.storeName === el.storeName)[0].sum;

              return (
                <div>
                  {currency === 'EUR' ? '€' : '$'}
                  {formatAmount(sum)}
                </div>
              );
            },
          },
        ],
      }));

      // add column Total Amount
      if (allByStores[0]?.length > 5) {
        const totalAmount = {
          fixed: 'left',
          title: 'Total Amount',
          align: 'left' as 'left',
          width: '160px',
          render: (_: any, row: any) => {
            return (
              <div>
                {currency === 'EUR' ? '€' : '$'}
                {formatAmount(row.sum)}
              </div>
            );
          },
        };

        outColumns.unshift(totalAmount);
      }

      return outColumns;
    } else {
      return [];
    }
  };

  const columns = [
    {
      title: 'Date',
      width: 140,
      align: 'left' as 'left',
      children: [
        {
          title: <div style={{ color: 'black' }}>Total for period</div>,
          dataIndex: 'date',
          width: 140,
          render: (_: any, row: any) => moment(row.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        },
      ],
      sorter: {
        compare: (a: any, b: any) => {
          return moment(a.date, 'YYYY-MM-DD').diff(moment(b.date, 'YYYY-MM-DD'));
        },
        multiple: 1,
      },
    },
    ...prepareColumns(),
  ];

  return (
    <div className={styles.wrapper}>
      <HeaderDailyRevenue
        requestParams={requestParams}
        listPdsAccounts={listPdsAccounts}
        currency={currency}
        setCurrency={setCurrency}
      />

      <DailyRevenueTable columns={columns} dataSource={listPdsRevenueByDays.proceeds} initialHeight="800px" />
    </div>
  );
};

export default DailyRevenue;
