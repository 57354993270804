/* eslint-disable max-lines */
import { formatDate, formatDateIsoShort } from 'helpers/formatters';
import { AoTypeEnum } from 'hooks/api/useActivationOrder';
import { ActivationOrderFormDataType, ErrorType } from 'types/ActivationOrderTypes';
import { KeyCardsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';

import { AdjustmentTypes, getAdjustmentCalcType, listAdjustmentTypes } from './AuditLog - old/data';

export type TypeAO = 'applaud_fix' | 'classic' | 'future_receivables' | 'available_receivables';

export type dateType = {
  date_type: string | null;
  description: string | null;
};

export type technicalInfoItemType = {
  adjustments: getAdjustmentCalcType[];
  date: string;
  date_type: dateType;
  interest: string;
  interest_accrued: string;
  interest_accrued_not_rounded: string;
  interest_net_accrued: string;
  interest_net_accrued_not_rounded: string;
  interest_not_rounded: string;
  interest_vat: string;
  interest_vat_not_rounded: string;
  interest_vat_total: string;
  interest_vat_total_not_rounded: string;
  loan_body: string;
  order_id: string;
  penalty: string;
  penalty_accrued: string;
  penalty_accrued_not_rounded: string;
  penalty_not_rounded: string;
  penalty_vat: string;
  penalty_vat_not_rounded: string;
  penalty_vat_total: string;
  penalty_vat_total_not_rounded: string;
  payable_balance: string;
  payable_not_rounded: string;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getColorModalTable = (dayType) => {
  const colors = {
    WORK_DAY: '#29292C',
    WEEKEND: '#6335E4',
    HOLIDAY: '#EC3E72',
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return { color: colors[dayType] };
};

export interface ITechnicalInfoTableConfig {
  field: string;
  title: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  dateType?: string;
  contentFunc?: Function;
  styles?: { minWidth?: number; maxWidth?: number; width?: number; paddingRight?: number };
  tooltipKey?: string;
}

export enum GeneralEnum {
  COMMENT = 'comment',
  CAPTION = 'caption',
  DOCUMENT_TYPE = 'document_type',
  COMPANY_NAME = 'company_name',
  COMPANY_ID = 'company_id',
  CONTRACT = 'contract_number',
  NAME = 'name',
  AMOUNT = 'amount',
  FILE = 'file',
  LINK = 'link',
  PURCHASE_DATE = 'purchase_date',
  PENALTY_POLICY = 'penalty_policy',
  STATUS = 'status',
  REPAYMENT_DATE = 'repayment_date',
  VAT_POLICY = 'vat_policy',
  LOAN_BALANCE = 'loan_balance',
  TYPE = 'ao_type',
  DEFAULT_INTEREST = 'interest',
  GRACE_PERIOD = 'grace_period',
  COMMISSION_DISABLE_DATE = 'commission_disable_date',
  TURN_OFF_CAPITALIZATION_UNTILL = 'capitalization_disable_date',
  DEFAULT_INTEREST_DISABLE_DATE = 'default_interest_disable_date',
  REPAYMENT_PLAN = 'repayment_plan',
  CAPITALIZATION_PERIOD_DISABLED = 'capitalization_period_disabled',
  REPAYMENT_PLAN_DURATION = 'repayment_plan_duration',
  CLOSING_DATE = 'closing_date',
  SIGN_DATE = 'sign_date',
  WITHHOLD_PERCENTAGE = 'withhold_percentage',
  PURCHASED_RECEIVABLES = 'purchased_receivables',
}

export enum ListEnumFieldEnum {
  INTEREST = 'interest',
  INTEREST_VAT_TOTAL = 'interest_vat_total',
  LOAN_BODY = 'loan_body',
  PENALTY = 'penalty',
  INTEREST_PLANNED = 'interest_planned',
}

export const listIsOpenData = { [KeyCardsEnum.TX_DATE]: false };
export type fileAODataType = {
  id?: string;
  name: string;
  type: string;
  number: string;
  date: string;
  link?: string;
  file_data: string;
  company_id?: string;
};

export const initialFileAOData: fileAODataType = {
  number: '',
  name: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  date: formatDateIsoShort(new Date()),
  type: '',
  link: '',
  file_data: '',
};

export interface documentDownload {
  date?: string;
  link?: string;
  number?: string;
  id?: string;
  setIsLoading?: (data: boolean) => void;
  isLoading?: (data: boolean) => void;
  type?: string;
}

export const getCorrectionCalculationTypes = (type: AdjustmentTypes, ao: AoTypeEnum) => {
  switch (type) {
    case listAdjustmentTypes[0]:
      return ListEnumFieldEnum.LOAN_BODY;
    case listAdjustmentTypes[1]:
      return AoTypeEnum.APPLAUD_FIX === ao ? ListEnumFieldEnum.INTEREST_PLANNED : ListEnumFieldEnum.INTEREST;
    case listAdjustmentTypes[2]:
      return ListEnumFieldEnum.PENALTY;
    case listAdjustmentTypes[3]:
      return ListEnumFieldEnum.PENALTY;
    default:
      return '';
  }
};

export const technicalInfoTableConfig: ITechnicalInfoTableConfig[] = [
  {
    field: 'date',
    title: 'forms.activationOrder.modalTable.date',
    dateType: 'date_type',
    contentFunc: formatDate,
    styles: { minWidth: 50 },
  },
  {
    field: ListEnumFieldEnum.LOAN_BODY,
    title: 'forms.activationOrder.modalTable.principalRepaymentAmount',
  },
  {
    field: ListEnumFieldEnum.INTEREST,
    title: 'forms.activationOrder.modalTable.commissionGross',
    tooltipKey: 'interest_not_rounded',
  },
  {
    field: 'interest_accrued',
    title: 'forms.activationOrder.modalTable.grossDaily',
    tooltipKey: 'interest_accrued_not_rounded',
  },
  {
    field: ListEnumFieldEnum.PENALTY,
    title: 'forms.activationOrder.modalTable.defaultInterest',
    tooltipKey: 'penalty_not_rounded',
  },
  {
    field: 'penalty_accrued',
    title: 'forms.activationOrder.modalTable.daily',
    tooltipKey: 'penalty_accrued_not_rounded',
  },
];

export const technicalInfoTableApplaud: ITechnicalInfoTableConfig[] = [
  {
    field: 'date',
    title: 'forms.activationOrder.modalTable.date',
    dateType: 'date_type',
    contentFunc: formatDate,
    styles: { minWidth: 50 },
  },
  {
    field: ListEnumFieldEnum.LOAN_BODY,
    title: 'forms.activationOrder.modalTable.principalRepaymentAmount',
  },
  {
    field: ListEnumFieldEnum.INTEREST_PLANNED,
    title: 'forms.activationOrder.modalTable.commissionRepaymentAmount',
    tooltipKey: 'interest_planned_not_rounded',
  },
  {
    field: 'payable',
    title: 'forms.activationOrder.modalTable.balancePayable',
    tooltipKey: 'payable_not_rounded',
  },
  {
    field: ListEnumFieldEnum.INTEREST,
    title: 'forms.activationOrder.modalTable.accruedCommission',
    tooltipKey: 'interest_not_rounded',
  },
  {
    field: ListEnumFieldEnum.PENALTY,
    title: 'forms.activationOrder.modalTable.di',
    tooltipKey: 'penalty_not_rounded',
  },
  {
    field: 'penalty_accrued',
    title: 'forms.activationOrder.modalTable.daily',
    tooltipKey: 'penalty_accrued_not_rounded',
  },
];

export const getValueVat = (value: string) => {
  switch (value) {
    case 'included':
      return 'selectFiledValues.included';
    case 'not_included':
      return 'selectFiledValues.not_included';
    case 'no_vat':
      return 'selectFiledValues.no_vat';
    default:
      return value;
  }
};

export const vat = {
  name: 'vat_policy',
  select: [
    { value: 'included', label: 'selectFiledValues.included' },
    { value: 'not_included', label: 'selectFiledValues.not_included' },
    { value: 'no_vat', label: 'selectFiledValues.no_vat' },
  ],
};

export const penalty = {
  name: 'penalty_policy',
  select: [
    { value: 'penalty_15_per_year', label: 'selectFiledValues.penalty_15_per_year' },
    { value: 'penalty_40_per_year', label: 'selectFiledValues.penalty_40_per_year' },
    { value: 'penalty_011_per_day', label: 'selectFiledValues.penalty_011_per_day' },
  ],
};

export type aoInfoResType = {
  commission_daily?: string | null;
  plan_commission_net?: string | null;
  plan_commission_vat?: string | null;
  plan_commission_gross?: string | null;
  commission_gross?: string | null;
  interest?: string | null;
  amount?: string | null;
  purchase_date?: string | null;
  repayment_date?: string | null;
  vat_policy?: string | null;
  days?: string | null;
};

export type aoInfoType = {
  field: string | null;
  label: string | null;
  value: string | null;
  digits: number | null;
};

export const aoInfo = {
  select: [
    { field: 'commission_daily', label: 'forms.activationOrder.commissionDaily', value: '0', digits: 8 },
    { field: 'plan_commission_net', label: 'forms.activationOrder.planCommissionNET', value: '0', digits: 2 },
    { field: 'plan_commission_vat', label: 'forms.activationOrder.planCommissionVAT', value: '0', digits: 2 },
    { field: 'plan_commission_gross', label: 'forms.activationOrder.planCommissionGROSS', value: '0', digits: 2 },
  ],
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getInitialError = (text): ErrorType => ({
  companyId: { status: false, text },
  name: { status: false, text },
  amount: { status: false, text },
  currency: { status: false, text },
  interest: { status: false, text },
  purchaseDate: { status: false, text },
  repaymentDate: { status: false, text: '' },
  apply_from_date: { status: false, text },
  contractDate: { status: false, text: '' },
  status: { status: false, text },
  commission_disable_date: { status: false, text },
  capitalization_disable_date: { status: false, text },
  default_interest_disable_date: { status: false, text },
  vat_policy: { status: false, text },
  grace_period: { status: false, text },
  days: { status: false, text },
  penalty_policy: { status: false, text },
  date_start_0: { status: false, text },
  date_end_0: { status: false, text },
});

export const initialActivationOrderFormData: ActivationOrderFormDataType = {
  companyId: '',
  name: '',
  amount: '',
  currency: '',
  interest: '',
  purchaseDate: '',
  repaymentDate: '',
  is_custom_payment_day: false,
  payment_days: [],
  status: '',
  contract_id: '',
  contractNumber: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  contractDate: formatDateIsoShort(new Date()),
  accounts: [],
  deletion: {
    can_be_deleted: true,
    reason: '',
  },
  active: true,
  custom_grace_period: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  grace_period: undefined,
  turn_off_commission: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  commission_disable_date: undefined,
  turn_off_capitalization: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  capitalization_disable_date: undefined,
  turn_off_default_interest: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  default_interest_disable_date: undefined,
  vat_policy: null,
  auto_debiting: false,
  has_transaction: false,
  days: '',
  ao_type: '',
  penalty_policy: null,
  is_capitalization_period_disabled: false,
  capitalization_period_disabled: [{ date_start: '', date_end: '' }],
};

export const choices = Array.from(Array(26).keys()).map((i) => String(i + 1));
export const choices_grace = Array.from(Array(10).keys()).map((i) => String(i + 1));

export const statusActivationOrderForm = [
  { id: 'ACTIVE', name: 'Active', active: true },
  { id: 'REPAID', name: 'Repaid', active: true },
  { id: 'CANCELLED', name: 'Cancelled', active: true },
];

export enum initialKeyFilterEnum {
  name = 'name',
  companyId = 'companyId',
  contract_id = 'contract_id',
  ao = 'ao',
  vat_policy = 'vat_policy',
  currency = 'currency',
  purchaseDate = 'purchaseDate',
  grace_period = 'grace_period',
  payment_day = 'payment_day',
  days = 'days',
  penalty_policy = 'penalty_policy',
  status = 'status',
  active = 'active',
  ao_type = 'ao_type',
  SORT = '_sort',
  ORDER = '_order',
  repaymentDate = 'repaymentDate',
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const fieldTranslate = (field) => {
  const fields = {
    [KeyCardsEnum.REPAYMENT_DATE]: initialKeyFilterEnum.repaymentDate,
    [KeyCardsEnum.PURCHASE_DATE]: initialKeyFilterEnum.purchaseDate,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return fields[field] || field;
};
