import React, { FC } from 'react';

import { activationOrderDataType } from 'hooks/api/useActivationOrder';
import GeneralInformation from 'components/forms/ActivationOrderForm/General/common/GeneralInformation';
import CustomSettings from 'components/forms/ActivationOrderForm/General/common/SettingCustom';
import { GeneralEnum } from 'components/forms/ActivationOrderForm/data';
import { Account } from 'types/ActivationOrderTypes';

type PropsT = {
  activationOrderData: activationOrderDataType;
  accounts: Account[];
  updateAccounts: (id: string) => void;
  setStatus?: any;
};

const ClassicAO: FC<PropsT> = ({ activationOrderData, accounts, updateAccounts, setStatus }) => {
  const isHasCustomSettings =
    activationOrderData.is_custom_payment_day ||
    activationOrderData[GeneralEnum.GRACE_PERIOD] ||
    activationOrderData[GeneralEnum.COMMISSION_DISABLE_DATE] ||
    activationOrderData[GeneralEnum.TURN_OFF_CAPITALIZATION_UNTILL] ||
    activationOrderData[GeneralEnum.DEFAULT_INTEREST_DISABLE_DATE] ||
    activationOrderData.capitalization_period_disabled?.length > 0;

  return (
    <>
      <GeneralInformation
        type="classic"
        activationOrderData={activationOrderData}
        accounts={accounts}
        updateAccounts={updateAccounts}
        setStatus={setStatus}
      />
      {isHasCustomSettings && (
        <CustomSettings
          activationOrderData={activationOrderData}
          shouldCustomSettings={[
            'paymentsDays',
            'gracePeriod',
            'commissionDisableDate',
            'capitalizationPeriodDisabled',
            'turnOffCapitalizationUntill',
            'defaultInterestDisableDate',
          ]}
        />
      )}
    </>
  );
};

export default ClassicAO;
